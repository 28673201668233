import TestimonialCard from "../components/TestimonialCard";
import classes from "./AboutMe.module.css";
import { IconContext } from "react-icons";

const AboutMePage = () => {
  return (
    <div className={classes.about_me_container}>
      <img
        src={require("../assets/profilePicture.png")}
        alt="Brian Robbins"
        className={classes.profile_image}
      ></img>
      <h1 className={`serif ${classes.about_me_title}`}>About Me</h1>
      <p className={`san-serif ${classes.about_me_discription}`}>
        I have always been interested in tinkering with things and learning how
        and why things work the way they do. This led me into my passion and
        interest which is computer science and web development. I went to the
        University of California Irvine and graduated in 2018 with a bachelors
        in Computer Science. Then I started my career at Parasoft as a Software
        Engineer. I was part of a team of six engineers and we worked on
        creating a suite of software testing tools that could be automated and
        virtualized. At Parasoft, I was responsible for three products. SOAtest
        which focused on tools related to xml parsing and validation, Virtualize
        which focused on more modern data models using json and validation
        tools, and a restiful api using OpenApi to build and update the suite of
        tools for both Soatest and Virtualize. While working at Parasoft I began
        learning web development on the side as a passion project. Once I felt I
        had acquired the skills necessary to be a web developer I landed a job
        as a UI Engineer at Chairish which actually turned out to be a full
        stack engineering position. Chairish is a website that is used to sell
        and buy high end vintage furniture and art. We operated as a hosting
        site of these products and facilitated shipping and payment processing
        while taking a portion of each sale. At Chairish I was part of a team of
        10 engineers. This was a mixture of Ui Engineers, Platform Engineers,
        Build Managers, and Management. In that position I collaborated with UX
        designers, project managers and other engineers to create seamless user
        experiences. In this role I used Vanilla Javascript, CSS and HTML for
        the frontend and Python, Django, and MySql for the backend. While I was
        at Chairish I helped create the paid seller plan structure and billing
        flow to increase revenue. I also made site wide search bar enhancements
        to increase user retention and completed orders. I implemented various
        A/B tests to make informed decisions for UI changes. I utilized product
        analytics software with in the site to track user usage data to make
        user driven UI decisions. While at Chairish I was also tasked with
        upgrading legacy code to ES6 to move away from RequireJS, to a newer
        more modern framework. While at Chairish we used jest and python unit
        tests to ensure bugs were caught early, which reduced code regression.
        Soon after I began an exciting career as a Full Stack Engineer at
        Quantum Research International. At Quantum we created software solutions
        for Aerospace, Military, Navy and Army. During my time there I got to
        work on all types of interesting projects. One of my favorite things
        about working at Quantum was that I was able to learn an array of
        different technologies. At Quantum, software engineers were expected to
        wear many hats. I built web-based applications using Vue.js and
        React.js. I worked on build engineer tasks such as deploying
        applications and maintaining and updating CI-CD pipelines. I also worked
        on desktop applications using C# and the .Net Framework. I built
        cross-platform mobile applications for iOS and Android as well. One
        unusual thing about this company was that they required us to work in
        pairs implementing paired programming. This was a great opportunity for
        me to mentor junior developers and provide them guidance and support
        throughout their professional development. This gave me opportunities to
        lead various team projects and assumed the responsibility of
        coordinating and delegating task where needed. One major accomplishment
        that I am proud of was improving their scrum practices so the software
        engineering team as a whole could run more smoothly and efficiently.
        After that I moved to my current company which is Omni Federal. Omni
        Federal is a medium large company that focuses on building software for
        the department of defense and other federal agencies. I worked on two
        rather large projects while at Omni Federal. The first project I was
        tasked with was to build an application for the Defense Health Agency. I
        was tasked with creating and leading a project that would allow
        researchers to upload large genetic files, creating studies and samples.
        Once the researchers uploaded files they could share files and studies
        with other researchers. We also created the capability to allow users to
        run predefined genetic analysis on there uploaded files which would give
        them an output file. I used React for the frontend and Node.js and
        Express for the backend and another server using node.js that strictly
        ran labor intensive python scripts for running genetic analysis. We used
        MSSQL for storing metadata and user data, S3 buckets for storing users
        files and created a logging functionality using cloudwatch. I worked
        with my project manager to create a team of 5 developers. 2 of them were
        junior developers 1 mid developers 1 tester and myself. This gave me a
        great opportunity to mentor junior developers and support their
        professional development. I finished that project in October and we
        recently received funding to continue that project starting sometime
        early 2025. This project was a success that resulted in us receiving
        other contracts with the defense health agency. The project I am
        currently working on is a tool for a military contractor that is an
        admin portal. This contractor is using analysis software to monitor user
        activity across a government websites. The contractor currently manually
        has to update users, update tracking urls, add users to authorization
        software and has to track all their interactions with customers and
        their status manually. My job is to create a portal for admin that
        automates creating users, creating tracking urls and keep track of
        customer and contractor project coordination. For the frontend I am
        using Next.js, Typescript and Keycloak which is authorization and
        authentication software. The backend consists of a Node.js and express
        server, Postgres for the database and Keycloak as well. I currently am
        the only developer on this team and I work closely with a Project
        Manager and UI Engineer.
      </p>
      <h1 className={`serif ${classes.testimonials_title}`}>Testimonials</h1>
      <IconContext.Provider value={{ size: "70px" }}>
        <div className={classes.testimonial_container}>
          <TestimonialCard
            title="Engineering Manager"
            description="I always strive to hire people that demonstrate leadership qualities and Brian is definitely a leader. He is humble, always willing to lend a helping hand, and goes above and beyond to make things better."
            characterId={1}
          />
          <TestimonialCard
            title="Project Manager"
            description="Brian is a great communicator and is able to articulate questions, solutions, and complex ideas in a manner that is easy to understand for technical and non-technical people alike"
            characterId={2}
          />
          <TestimonialCard
            title="Platform Engineer"
            description="Brian did a great job prioritizing his work and completing his sprints, helping others in code review by providing kind, thoughtful feedback, and he led by example by helping improve processes, documentation, coding best practices, and much more"
            characterId={3}
          />
        </div>
      </IconContext.Provider>
    </div>
  );
};

export default AboutMePage;
