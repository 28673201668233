import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classes from "./GlobalNav.module.css";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons";

const GlobalNav = () => {
  const [isDropDownShowing, setDropDownShowing] = useState(false);

  //Toggles Drop Down Menu
  const toggleMenuClick = () => {
    setDropDownShowing(!isDropDownShowing);
  };

  return (
    <nav className={classes.navbar}>
      <div className={classes.name_container}>
        <h1 className={`serif ${classes.name}`}>Brian Robbins</h1>
      </div>
      <div className={classes.link_container}>
        <NavLink to={"/"} className={`san-serif ${classes.link}`}>
          Projects
        </NavLink>
        <NavLink to={"/aboutme"} className={`san-serif ${classes.link}`}>
          About Me
        </NavLink>
        <NavLink to={"/resume"} className={`san-serif ${classes.link}`}>
          Resume
        </NavLink>
      </div>
      <IconContext.Provider value={{ color: "#0e8388", size: "25px" }}>
        <div className={classes.menu_button_container}>
          <FaBars onClick={toggleMenuClick} />
        </div>
      </IconContext.Provider>
      <div
        className={
          isDropDownShowing
            ? `${classes.mobile_dropdown} ${classes.mobile_dropdown_showing}`
            : `${classes.mobile_dropdown} ${classes.mobile_dropdown_hidden}`
        }
      >
        <NavLink
          onClick={toggleMenuClick}
          to={"/"}
          className={`san-serif ${classes.link}`}
        >
          Projects
        </NavLink>
        <NavLink
          onClick={toggleMenuClick}
          to={"/aboutme"}
          className={`san-serif ${classes.link}`}
        >
          About Me
        </NavLink>
        <NavLink
          onClick={toggleMenuClick}
          to={"/resume"}
          className={`san-serif ${classes.link}`}
        >
          Resume
        </NavLink>
      </div>
    </nav>
  );
};

export default GlobalNav;
